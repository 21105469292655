export enum BannerTag {
  /** 티어 */
  TIER = 'tier',
  /** 도구 */
  UTILITY = 'utility',
  /** 왕국 */
  KINGDOM_AFFAIR = 'kingdom_affair',
  /** 콘텐츠 */
  CONTENT = 'content',
  SEASONAL_EVENT = 'seasonal_event',
  UPDATE = 'update',
  COMMUNITY = 'community',
}

export interface MainBanner {
  cover: {
    url: string
  }
  tags: string[]
  title: string
  description: string
  link: string
}
