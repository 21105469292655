import { BannerProps } from '@/components/ckk/Banner'
import Img from '@/components/common/Img'

export const KAKAO_OPEN_CHAT_BANNER: BannerProps = {
  title: {
    ko: '쿠키런 킹덤 오픈채팅방',
    en: "Cookie Run: Kingdom's Kakao Open Chat",
  },
  description: {
    ko: (
      <span>
        설탕노움의 오픈채팅방에서 다양한 정보 공유하고, 업데이트 소식을
        받아보세요!
      </span>
    ),
    en: 'Share various information and get updates!',
  },
  image: {
    url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/5ed2accc-3eda-4213-f003-4844cc83bb00/public',
  },
  onClick: () => {
    window.open(
      'https://open.kakao.com/o/gcSZYHIe',
      '_blank',
      'noopener,noreferrer',
    )
  },
}
export const CKT_KAKAO_OPEN_CHAT_BANNER: BannerProps = {
  title: {
    ko: '쿠키런 모험의 탑 오픈채팅방',
    en: "Cookie Run: Tower of Adventures's Kakao Open Chat",
  },
  description: {
    ko: (
      <span>
        설탕노움의 오픈채팅방에서 다양한 정보 공유하고, 업데이트 소식을
        받아보세요!
      </span>
    ),
    en: 'Share various information and get updates!',
  },
  image: {
    url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/5ed2accc-3eda-4213-f003-4844cc83bb00/public',
  },
  onClick: () => {
    window.open(
      'https://open.kakao.com/o/gygeJizg',
      '_blank',
      'noopener,noreferrer',
    )
  },
}

export const DISCORD_BANNER: BannerProps = {
  title: {
    ko: '설탕노움의 비밀창고 디스코드',
    en: (
      <span>
        <Img
          src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/96758525-d559-4c2c-d861-9e66f7b35500/public"
          style={{
            width: '1rem',
            height: '1rem',
            objectFit: 'contain',
            verticalAlign: 'middle',
            marginRight: '0.5rem',
          }}
        />
        <span>Cookie Run: Kingdom Community Discord</span>
      </span>
    ),
  },
  description: {
    ko: '설탕노움의 디스코드에서 다양한 정보 공유하고, 업데이트 소식을 받아보세요!',
    en: 'Share various information and get updates!',
  },
  image: {
    url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/5ed2accc-3eda-4213-f003-4844cc83bb00/public',
  },
  onClick: () => {
    window.open('https://discord.gg/QJZP6NsH', '_blank', 'noopener,noreferrer')
  },
}

export const ANDROID_APP_BANNER: BannerProps = {
  title: {
    ko: (
      <div className="flex items-center gap-2">
        <Img
          src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/6b935cb8-bc03-4a29-6206-c4ea2f967b00/publicIcon"
          style={{
            width: '1rem',
            height: '1rem',
            objectFit: 'contain',
            verticalAlign: 'middle',
            marginRight: '0.5rem',
          }}
        />
        <span>설탕노움의 비밀창고 앱</span>
      </div>
    ),
    en: (
      <div className="flex items-center gap-2">
        <Img
          src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/6b935cb8-bc03-4a29-6206-c4ea2f967b00/publicIcon"
          style={{
            width: '1rem',
            height: '1rem',
            objectFit: 'contain',
            verticalAlign: 'middle',
            marginRight: '0.5rem',
          }}
        />
        <span>Sugar Gnome's Warehouse App</span>
      </div>
    ),
  },
  description: {
    ko: (
      <span>
        설탕노움의 비밀창고 앱에서
        <br />
        새로운 쿠폰 알림을 받아보세요!
      </span>
    ),
    en: "Get a new coupon noti from the Sugar Gnome's Warehouse app!",
  },
  image: {
    url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/78f73f8a-ca5a-4152-fc95-f71c34639700/public',
  },
  onClick: () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.run_quokka.sugar_gnome',
      '_blank',
      'noopener,noreferrer',
    )
  },
}
export const APPLE_APP_BANNER: BannerProps = {
  title: {
    ko: (
      <span>
        <Img
          src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/60bd35b8-5feb-4f2c-c504-ce47aaaad300/publicIcon"
          style={{
            width: '1rem',
            height: '1rem',
            objectFit: 'contain',
            verticalAlign: 'middle',
            marginRight: '0.5rem',
          }}
        />
        <span>설탕노움의 비밀창고 앱</span>
      </span>
    ),
    en: (
      <span>
        <Img
          src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/60bd35b8-5feb-4f2c-c504-ce47aaaad300/publicIcon"
          style={{
            width: '1rem',
            height: '1rem',
            objectFit: 'contain',
            verticalAlign: 'middle',
            marginRight: '0.5rem',
          }}
        />
        <span>Sugar Gnome's Warehouse App</span>
      </span>
    ),
  },
  description: {
    ko: (
      <span>
        이제 아이폰에서도 설탕노움의 비밀창고 앱을 통해 새로운 쿠폰 알림을
        받아보세요!
      </span>
    ),
    en: "Get a new coupon noti from the Sugar Gnome's Warehouse app!",
  },
  image: {
    url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/78f73f8a-ca5a-4152-fc95-f71c34639700/public',
  },
  onClick: () => {
    window.open(
      'https://apps.apple.com/kr/app/%EC%84%A4%ED%83%95%EB%85%B8%EC%9B%80%EC%9D%98-%EB%B9%84%EB%B0%80%EC%B0%BD%EA%B3%A0/id6446649245',
      '_blank',
      'noopener,noreferrer',
    )
  },
}

export const ARENA_DECK_EVENT_BANNER: BannerProps = {
  title: {
    ko: '아레나 덱 업데이트 기념 이벤트',
    en: "Sugar Gnome's Warehouse App",
  },
  description: {
    ko: (
      <span>
        아레나 덱을 작성하고
        <br />
        구글 기프트 카드를 받아보세요!
      </span>
    ),
    en: "Get a new coupon noti from the Sugar Gnome's Warehouse app!",
  },
  image: {
    url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/507e308b-d959-4694-98d2-a05ca52e8a00/public',
  },
  onClick: () => {
    window.open(
      'https://joel-lee.notion.site/65983fefbd314ff5a6478778bf83ed3a?pvs=4',
      '_blank',
      'noopener,noreferrer',
    )
  },
}

export const DONATION_BANNER: BannerProps = {
  title: {
    ko: '설탕노움의 비밀창고 후원하기',
    en: "Donate for Sugar Gnome's Warehouse",
  },
  description: {
    ko: '설탕노움의 비밀창고는 여러분의 후원으로 유지됩니다.',
    en: "Sugar Gnome's Warehouse is maintained under your patronage.",
  },
  image: {
    url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/bc13f583-8e00-4b5b-7e0d-db8f035a2a00/public',
  },
  onClick: () => {
    window.open('https://toss.me/달려라쿼카', '_blank', 'noopener,noreferrer')
  },
}

export const FEEDBACK_BANNER: BannerProps = {
  title: {
    ko: (
      <span>
        비즈니스 문의 및
        <br />
        피드백 보내기
      </span>
    ),
    en: 'Send feedback or business inquiries',
  },
  description: {
    ko: (
      <span>
        유저분들의 피드백과
        <br />
        함께 성장합니다!
      </span>
    ),
    en: (
      <span>
        We grows
        <br />
        with user feedback!
      </span>
    ),
  },
  image: {
    url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/ff5f2be4-84bc-4b04-19ac-de53ce4dd400/public',
  },
}
