import { Size } from '@/types/shared'

export const getFixedStyle = (px: number) => ({
  width: `${px / 16}rem`,
  height: `${px / 16}rem`,
  // maxWidth: `${px / 16}rem`,
  // maxHeight: `${px / 16}rem`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objectFit: 'contain' as any,
})

export const PX_MAP: Record<Size, number> = {
  xxs: 17,
  xs: 20,
  sm: 40,
  md: 48,
  lg: 100,
  xl: 250,
  xxl: 370,
}

export const getStyleFromSize = (
  size: Size = 'md',
  customStyle?: Partial<Record<Size, number>>,
) => {
  return getFixedStyle(customStyle?.[size] ?? PX_MAP[size])
}
