import { useRouter } from 'next/router'

import React from 'react'

import Img from '@/components/common/Img'
import { LocalizedReactNode } from '@/types/ckk'

export interface BannerProps {
  title: LocalizedReactNode
  description: LocalizedReactNode
  image: {
    url: string
    className?: string
  }
  className?: string
  onClick?: () => void
  style?: React.CSSProperties
}

const Banner: React.FC<BannerProps> = ({
  title,
  description,
  image,
  className,
  onClick,
  style,
}) => {
  const { locale } = useRouter()

  return (
    <div
      className={`relative flex h-32 w-full cursor-pointer justify-start overflow-hidden rounded-sm p-6 shadow ${className}`}
      style={style}
      onClick={onClick}>
      <div className="relative z-[1] flex max-w-[12rem] flex-col">
        <div className="ckkBold mr-4 leading-5" style={{ fontSize: '0.9rem' }}>
          {title[locale]}
        </div>
        <div className="mt-2 text-xs font-light">{description[locale]}</div>
      </div>
      <Img
        alt="배너 이미지"
        className={`absolute bottom-0 right-0 w-1/2 max-w-[13rem] translate-x-[5%] translate-y-[10%] ${image.className}`}
        src={image.url}
        title="배너 이미지"
      />
    </div>
  )
}

export default Banner
